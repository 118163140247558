<template>
  <div class="index_warp">
    <!-- <div class="mode">当前处于{{userInfo.mode}}</div> -->
    <div class="top_input">
      <a-input placeholder="输入题目ID搜索题目"
               @keydown.enter="searchTopic"
               v-model:value="keywords">
        <template #suffix>
          <img src="@/static/icons/search.png"
               alt=""
               @click="searchTopic">
          <!-- <search-outlined  /> -->
        </template>
      </a-input>
      <div class="goal_text">为理想而战<img style=" line-height: 42px;"
             src="@/static/medal.png"
             alt="">
      </div>
      <button class="btn_goal"
              @click="toGoal">
        <div class="text" v-if="totalScore !== 0"> 目标总分 {{ totalScore }}分 ></div>
        <div class="text" v-else> 快去填写{{edu_grade_id==1?'中考目标':"学科目标"}}> </div>
      </button>
    </div>

    <div class="top">
      <div class="top_block"
           v-if="showTemplateTest&&showTop">
        <div class="inner_wrap">
          <div class="white_wrap"
               @click="toPage('/templatePaper')">
            <div class="text">
              模板组卷
            </div>
            <div class="content">
              <span>模拟中考</span>
            </div>
            <div class="images">
              <img src="@/static/index/template.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="top_block"
           v-if="showTop">
        <div class="inner_wrap">
          <div class="white_wrap"
               @click="toPage('/testpaper/template')">
            <div class="text">
              整卷测试
            </div>
            <div class="content">
              <span>模拟测试</span>
              <span>中考真题 </span>
              <span>模拟题</span>
            </div>
            <div class="images">
              <img src="@/static/index/test.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="top_block"
           v-if="showTop">
        <div class="inner_wrap">
          <div class="white_wrap"
               @click="toPage('/capability')">
            <div class="text">
              核心素养
            </div>
            <div class="content">
              <span>按核心素养进行训练</span>
            </div>
            <div class="images">
              <img src="@/static/index/cap.png"
                  
                   alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="top_block"
           v-if="showTop">
        <div class="inner_wrap">
          <div class="white_wrap"
               @click="toPage('/mytestpaper')">
            <div class="text">
              {{userInfo.correct_type == 2?'自评试卷':'我的试卷'}}
            </div>
            <div class="content">
              查看所有试卷情况
            </div>
            <div class="images">
              <img src="@/static/index/paper.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>

      <div class="top_block"
           v-if="showTop">
        <div class="inner_wrap">
          <div class="white_wrap"
               @click="toPage('/errorBook')">
            <div class="text">
              错题本
            </div>
            <div class="content">
              查看所有收藏的错题
            </div>
            <div class="images">
              <img src="@/static/index/ctb.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex">
      <Card ref="CardRef"
            title="考点训练"
            url="/knowledge"
            @toInfo="toInfo"
            btn_text="进入训练"
            :list="list" />

      <CardWeak ref="CardRef"
                title="弱点训练"
                url="/knowledgeWeak"
                @toInfo="toWeakInfo"
                btn_text="训练"
                :list="weakLlist" />
    </div>

  </div>
</template>

<script setup>
import { SearchOutlined } from '@ant-design/icons-vue'
import { reactive, ref, toRef, toRefs } from '@vue/reactivity'
import { backgColorProperty } from './property.js'
import { onMounted } from '@vue/runtime-core'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { getHomeKnowledge, getHomeWeak } from '@/api/knowledge.js'
import { getTopic } from '@/api/topic.js'
import Card from './components/card.vue'
import CardWeak from './components/cardWeak.vue'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { getTestModeSubject } from "@/api/subject.js"

const router = useRouter()
let list = ref([])
let keywords = ref('')
let weakLlist = ref([])
let totalScore = ref(0)
let backgColor = backgColorProperty
let timeInterValId = ref(0)
let userInfo = reactive({})
let store = useStore()
const edu_grade_id = ref(null)
const searchTopic = async () => {
  if (!keywords.value) {
    return message.warn('请输入题目ID')
  }
  const { data } = await getTopic({
    question_id: keywords.value
  })
  if (data.list.length) {
    router.push('/showTopic?topicId=' + keywords.value)
  }
}

onMounted(() => {
  edu_grade_id.value = JSON.parse(window.localStorage.getItem('userInfo')).edu_grade_id 
  getTestSubject()
  getKnowledge()
  getWeakness()
  getUserInfo()
  totalScore.value = JSON.parse(window.localStorage.getItem('totalScore'));
    // this.$EventBus.on('totalScoreEdit', data => {
    //   totalScore.value = data
    // })
    if (totalScore.value > 0) {
      window.localStorage.setItem('score_dialog', false)
    }
  store.commit('CLEAR_ROUTE_CACHE')
})

onBeforeRouteLeave((to, from, next) => {
  let includeRouteNames = ['/mytestpaper', '/testpaper/template', '/dayi/info']
  if (includeRouteNames.includes(to.name)) {
    store.commit('SET_ROUTE_LIST', to.name)
  }
  next()
})
const toGoal = () => {
  router.push('/userInfo/goal')
}
// 获取用户信息
const getUserInfo = () => {
  let cacheUserInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  for (const key in cacheUserInfo) {
    userInfo[key] = cacheUserInfo[key]
  }
}

let showTemplateTest = ref(false)
let showTop = ref(false) //等确定是否显示模板组卷再显示其它组卷模块
// 获取备考科目
const getTestSubject = async () => {
  try {
    let { data } = await getTestModeSubject()
    console.log('data', data)
    if (data && data.list && data.list.length > 0) {
      showTemplateTest.value = true
    } else {
      showTemplateTest.value = false
    }
    showTop.value = true
  } catch {
    showTop.value = true
  }

}

// 跳转页面
const toPage = (str) => {
  router.push(str)
}
const getKnowledge = async () => {
  const { data } = await getHomeKnowledge()
  list.value = data.list
}

const getWeakness = async () => {
  const { data } = await getHomeWeak()
  weakLlist.value = data.list
}

const toInfo = (item) => {
  let form = {
    knowledge_id: item.knowledge_id,
    subject_id: item.subject_id
  }
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('paper')
  window.sessionStorage.removeItem('testpaperCardIndex')
  // router.push('/testpaper/paperinfo?isKnowledge=1&form=' + JSON.stringify(form))
  router.push('/doPaper?isKnowledge=1&form=' + JSON.stringify(form))

}

const toWeakInfo = (item) => {
  let form = {
    knowledge_id: item.knowledge_id,
    subject_id: item.subject_id
  }
  window.sessionStorage.removeItem('images')
  window.sessionStorage.removeItem('paper')
  window.sessionStorage.removeItem('testpaperCardIndex')
  router.push('/doPaper?isKnowledgeWeak=1&form=' + JSON.stringify(form))

}


</script>

<style lang="scss" scoped>
.index_warp {
  // margin: 0 auto;
  margin-top: 4vh;
  // margin-left: 90rem;
  margin-left: 4.76vw;

  .mode {
    margin-bottom: 7px;
    font-size: 22px;
    font-weight: 800;
    color: #ffffff;
    text-indent: 5px;
  }

  .top {
    display: flex;
    margin-bottom: 5vh;
    margin-top: 5vh;
    display: flex;
    margin-right: 120rem;
    height: 400rem;
    .top_block {
      // width: 350rem;
      flex: 1;
      height: 400rem;
      position: relative;
      // background-image: url("../../static/index/zjcs.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      // text-indent: 48rem;
      margin-left: 35rem;
      background: #edc96f;
      box-shadow: 0px 1px 8px 0px #b56b0a,
        inset 2px 2px 6px 0px rgba(255, 255, 255, 0.53);
      border-radius: 48rem;

      .inner_wrap {
        cursor: pointer;
        width: calc(100% - 32rem);
        height: 368rem;
        margin: 16rem;
        background: #e9a955;
        box-shadow: inset 0px -2rem 10rem 0px #a86b21;
        border-radius: 32rem;
        opacity: 1;
        border: 1px solid #c88637;
      }
      .white_wrap {
        // width: 302rem;
        width: calc(100% - 16rem);
        height: 352rem;
        margin: 8rem;
        padding: 32rem;
        background: #ffffff;
        box-shadow: 0px 2rem 6rem 0px #be771f;
        border-radius: 24rem;
      }
      .text {
        font-size: 32rem;
        // font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #1a1a1a;
        line-height: 48rem;
        margin-bottom: 16rem;
      }
      .content {
        font-size: 20rem;
        font-weight: 400;
        line-height: 30rem;
        color: #666666;
        span {
          display: block;
          margin-bottom: 4rem;
        }
      }
      .images {
        position: absolute;

        bottom: 50rem;
        right: 50rem;
        img {
          width: 120rem;
          height: 120rem;
          // position: absolute;
        }
      }
    }
  }
}
.top_input {
  margin-top: 20rem;
  margin-left: 50rem;
  justify-content: space-around;
  align-items: center;
  display: flex;
  :deep .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0;
  }
  .goal_text {
    color: var(--light-333333, var(--neutral-color-333333, #fff));
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 131.25% */
  }
  .btn_goal {
    display: flex;
    padding: var(--spacer-8_xs, 8px) var(--spacer-24_m, 24px);
    align-items: center;
    gap: var(--spacer-8_xs, 8px);
    border-radius: 100px;
    border: 1px solid #f37f21;
    background: #f37f21;
    .text {
      overflow: hidden;
      color: var(--light-ffffff, #fff);
      text-overflow: ellipsis;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }
  }
  .ant-input-affix-wrapper {
    width: 320rem;
    height: 48rem;
    padding: 0rem;
    box-shadow: 0;
    // border-radius: 30rem;
    padding-right: 12rem;
    background: transparent;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
    font-weight: 400;

    :deep .ant-input-suffix {
      color: #fff;
      padding: 0 0rem;
      img {
        width: 32rem;
        height: 32rem;
        cursor: pointer;
      }
    }
    :deep .ant-input {
      font-size: 24rem;
      line-height: 36rem;
      font-weight: 400;
      padding: 0;
      color: rgba(255, 255, 255, 0.38);
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.38);
        font-weight: 400;
      }
      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.38);
        font-weight: 400;
      }
    }
  }
  .ant-input-affix-wrapper-focused {
    // background-color: rgb(250, 250, 250, 1);
    box-shadow: 0 0 0;
    :deep .ant-input-suffix {
      // color: #336;
    }
    :deep .ant-input {
      // color: #336;
      color: #fff;
      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.38);
      }
      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.38);
      }
    }
  }

  :deep .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 0;
  }
  :deep .ant-input {
    background-color: transparent;
    padding-left: 12rem;
    &::-webkit-input-placeholder {
      color: #eeeeff;
    }
    &::-moz-placeholder {
      color: #eeeeff;
    }
  }
}
</style>